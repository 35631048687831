/**
 * 这里是常用的一些过滤器
 */
import utils from './index.js';
const filters = {
  time(value, format) {
    return utils.timeFormat(value, format);
  },
  price(value, scale = 100) {
    return value ? (value / scale).toFixed(2) : '0.00';
  },
  percent(value, max = 100, decimal = 2) {
    return max ? `${((value / max) * 100).toFixed(decimal)}%` : '0.00%';
  },
  rate(value) {
    return (value * 100).toFixed(2) + '%';
  },
  number(value) {
    return value !== null ? utils.addSeparator(value) : 'N/A';
  },
  discount(value) {
    if (value % 10 === 0) return value / 10;
    return (value / 10).toFixed(1);
  }
};

export default filters;
