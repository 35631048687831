'use strict';
import 'promise-polyfill/src/polyfill';
import 'element-ui/lib/theme-chalk/index.css';
import '@/dss-common/style/base.css';
import '@/dss-common/style/anim.css';
import '@/dss-common/partial/key-header/tpl.less';
// import '@/dss-common/partial/key-header/console.css';
import '@/dss-common/partial/key-footer/tpl.less';

import '@/dss-common/style/base.less';
import '@/dss-common/style/custom.css';
import '@/dss-common/style/icon2.css';
import baseApi from './api';
import filters from '@/dss-common/utils/filters';
import store from '@/dss-common/store/base';
import VuePlain from '@/dss-common/vue-plugins/vue-plain';

import ElementUI from 'element-ui';
import Vue from 'vue';
import App from '../modules/manage/App';
import router from '../router/index';
import '@/dss-common/style/wkt-base.less';

window.Promise = Promise;

// 注册过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

Vue.use(ElementUI);
Vue.use(VuePlain);

// baseApi.getAccountList().then(res => {
//
// }).catch(err => {
//   console.log(err);
// });

baseApi.checklogin(true, true).done(res => {
  store.commit('set_userInfo', res.data);
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});
