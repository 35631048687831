/**
 * Created by admin on 2019/4/23.
 * @author liyili<李亦黎>
 */
import services from '@/dss-common/utils/services';

function gotoLogin() {
  const url = encodeURIComponent(window.location.href);
  window.top.location.href = `/login.html?gotoUrl=${url}`;
}
/**
 *设置菜单，登录，用户名控制显示
 *
 * @param {boolean} [isLogined=false]
 * @param {*} userName
 * @param {*} epName
 */
function setMenu(isLogined = false, userName, epName) {
  const trialAndLogin = document.getElementById('trialAndLogin');
  const userDiv = document.getElementById('avatar');
  const loc = window.location;
  if (isLogined) {
    trialAndLogin.style.display = 'none';
    userDiv.style.display = '';
    if (!userName) {
      userDiv.querySelector('span').innerText = epName;
    } else {
      userDiv.querySelector('span').innerText = userName;
    }
    userDiv.querySelector('#logout').href =
      '/permission/web/permission/login/logout?callback=' +
      loc.protocol +
      '//' +
      loc.hostname +
      (loc.port ? ':' + loc.port : '') +
      '&_t=' +
      Date.parse(new Date()) / 1000;
  } else {
    trialAndLogin.style.display = '';
    userDiv.style.display = 'none';
  }
}
export default {
  login(params) {
    return services.post('/c/login/login', params, {
      action: '登录',
    });
  },
  /**
   * 获取登陆账号列表todo
   * **/
  getAccountList(tenantId) {
    return services.get(`/c/login/run_as?tenantId=${tenantId}`, {
      action: '获取账号列表',
    });
  },
  showList(pageSize, pageNo, appName) {
    return services.get(`/wp/seller_app/list_all?pageSize=${pageSize}&pageNo=${pageNo}&appName=${appName}`, {
      action: '获取店铺列表',
    });
  },
  checkRunAs() {
    return services.get('/c/login/is_run_as  ', {
      action: '查看是否是runAs状态',
    });
  },
  releaseRunAs() {
    return services.get('/c/login/release_run_as', {
      action: '发布runAs',
    });
  },
  getBrandInfo(pageNo, pageSize, name) {
    return services.get(`/mg/app/list?pageNo=${pageNo}&pageSize=${pageSize}&name=${name}`, {
      action: '获取品牌信息',
    });
  },
  updateBrandInfo(param) {
    return services.post(`/mg/app/update`, param, {
      action: '更新品牌信息',
    });
  },
  getUserInfo() {
    return services.get('/wp/loginV2/query', {
      action: '获取用户信息',
    });
  },
  newGetUserInfo() {
    return services.get('/permission/web/permission/login/query', {
      action: '获取用户信息',
    });
  },
  getUserMenu(source) {
    return services.get('/c/menu/queryUserMenu', {
      action: '获取用户菜单',
      params: {
        source: source || 1,
      },
    });
  },
  getEpList() {
    return services.get('/c/login/login', {
      action: '获取企业列表',
      params: {
        userName: 'test',
      },
    });
  },
  // 超级管理源 切换
  changeEp(tenantId, storeId) {
    const params = {
      tenantId,
    };
    if (storeId) {
      params.storeId = storeId;
    }
    return services.get('/c/pot/user/choosee', {
      action: '切换企业',
      params,
    });
  },
  changeStore(storeId) {
    return services.get('/c/pot/user/choosestore', {
      action: '切换店铺',
      params: {
        storeId,
      },
    });
  },
  // 切换店铺
  changeStoreEp(storeId) {
    return services.get('/c/pot/user/choosestore', {
      action: '切换企业',
      params: {
        storeId: storeId,
      },
    });
  },
  checkCode_resetPwd(params) {
    return services.post('/c/pot/rspd/checkVCode', params, {
      action: '校验验证码',
    });
  },
  changePwd(params) {
    return services.post('/c/pot/rspd/change', params, {
      action: '重置密码',
    });
  },
  smsCode_resetPwd(phone) {
    return services.post(
      '/c/pot/rspd/sendVCode',
      {
        phone: phone,
      },
      {
        action: '获取验证码',
      }
    );
  },
  smsCode_login(phone) {
    return services.post(
      '/c/login/sendVCode',
      {
        phone: phone,
      },
      {
        action: '获取验证码',
      }
    );
  },
  shopKeeper() {
    return services.get('/c/statistic/index/list/shopkeeper', {
      action: '获取店主数据',
    });
  },
  storeList() {
    return services.get('/c/statistic/report/meta/store', {
      action: '获取店铺数据',
    });
  },
  quota() {
    return services.get('/c/statistic/index/listQuota', {
      action: '获取指标说明',
    });
  },
  /**
   * 检验登录状态
   *
   * @param {boolean} [isSetMenu=true] 有引入dss-commmon/partial的顶部菜单设置为ture,否则为false
   * @param {boolean} [isRedirect=true] 是否跳转登录页，不需要登录可查看的页面设置为false
   * @returns
   */
  checklogin(isSetMenu = true, isRedirect = true) {
    const p = new Promise((resolve, reject) => {
      this.newGetUserInfo().then(
        (res) => {
          const { data } = res;
          const { account: userName, id: userId, account: epName } = data;
          if (!res.success || !userId) {
            if (isRedirect) {
              gotoLogin();
            } else if (isSetMenu && res.success) {
              setMenu(!!userId, userName, epName);
            }
            res.success = false;
            return reject(res);
          }
          if (isSetMenu) {
            setMenu(!!userId, userName, epName);
          }
          return resolve(res);
        },
        (res) => {
          if (isRedirect) {
            gotoLogin();
          } else if (isSetMenu) {
            setMenu();
          }
          return reject(res);
        }
      );
    });

    return p;
  },
  uploadUrl: '/c/pot/file/upload',
  downloadUrl: '/c/pot/file/download',
  templateUrl: '/c/pot/template/download',
};
